<mat-sidenav-container class="demo-container">
  <mat-sidenav #navigation role="navigation">
    <mat-nav-list>
      @for (navItem of navItems; track navItem) {
        <a
          mat-list-item
          (click)="navigation.close()"
          routerLinkActive
          #routerLinkActiveInstance="routerLinkActive"
          [attr.tabindex]="routerLinkActiveInstance.isActive ? 0 : -1"
          [routerLink]="[navItem.route]"
          >{{navItem.name}}</a
        >
      }

      <mat-divider></mat-divider>

      <a mat-list-item tabindex="-1" (click)="navigation.close()" [routerLink]="['/baseline']">
        Baseline
      </a>

      <a mat-list-item tabindex="-1" (click)="navigation.close()" [routerLink]="['/performance']">
        Performance
      </a>
    </mat-nav-list>
    <button mat-button tabindex="-1" (click)="navigation.close()">CLOSE</button>
  </mat-sidenav>
  <!--
    Note that the setup with the directionality and density is a little convoluted, but it's
    organized this way so that we can test MDC's density styles in both LTR and RTL. Their mixins
    output styles in the form of `[dir='rtl'] .density-min .foo` which means that the `dir` has to
    be one level above the density class in the DOM. At the same time, we want the density to apply
    to the toolbar while always keeping it in LTR at the same time.
   -->
  <main [attr.dir]="state.direction" [ngClass]="getDensityClass()" class="demo-main">
    <!-- The toolbar should always be in the LTR direction -->
    <mat-toolbar color="primary" dir="ltr">
      <button mat-icon-button (click)="navigation.open('mouse')">
        <mat-icon>menu</mat-icon>
      </button>
      <div class="demo-toolbar">
        <h1>Angular Material Demos</h1>
        <div class="demo-config-buttons">
          @if (state.m3Enabled) {
            <button
              mat-icon-button
              (click)="toggleColorApiBackCompat()"
              matTooltip="{{state.colorApiBackCompat ? 'Disable' : 'Enable'}} color API back-compat"
            >
              <mat-icon>colorize</mat-icon>
            </button>
          }
          <button mat-icon-button (click)="toggleFullscreen()" matTooltip="Toggle fullscreen">
            <mat-icon>fullscreen</mat-icon>
          </button>
          <button
            mat-icon-button
            (click)="toggleM3()"
            [matTooltip]="state.m3Enabled ? 'Use M2 theme' : 'Use M3 theme'"
          >
            @if (state.m3Enabled) {
              <mat-icon>invert_colors_off</mat-icon>
            } @else {
              <mat-icon>invert_colors</mat-icon>
            }
          </button>
          <button
            mat-icon-button
            (click)="toggleZoneless()"
            [matTooltip]="isZoneless ? 'Use zones' : 'Use zoneless'"
          >
            @if (isZoneless) {
              <mat-icon>visibility</mat-icon>
            } @else {
              <mat-icon>visibility_off</mat-icon>
            }
          </button>
          <button
            mat-icon-button
            (click)="toggleAnimations()"
            [matTooltip]="state.animations ? 'Disable animations' : 'Enable animations'"
          >
            @if (state.animations) {
              <mat-icon>pause_circle</mat-icon>
            } @else {
              <mat-icon>animation</mat-icon>
            }
          </button>
          <button
            mat-icon-button
            (click)="toggleTheme()"
            [matTooltip]="state.darkTheme ? 'Switch to light theme' : 'Switch to dark theme'"
          >
            @if (state.darkTheme) {
              <mat-icon>light_mode</mat-icon>
            } @else {
              <mat-icon>dark_mode</mat-icon>
            }
          </button>
          <button
            mat-icon-button
            (click)="toggleRippleDisabled()"
            [matTooltip]="state.rippleDisabled ? 'Enable ripples' : 'Disable ripples'"
          >
            @if (state.rippleDisabled) {
              <mat-icon>waves</mat-icon>
            } @else {
              <mat-icon>water</mat-icon>
            }
          </button>
          <button
            mat-icon-button
            (click)="toggleStrongFocus()"
            [matTooltip]="state.strongFocusEnabled ? 'Disable strong focus' : 'Enable strong focus'"
          >
            @if (state.strongFocusEnabled) {
              <mat-icon>not_accessible</mat-icon>
            } @else {
              <mat-icon>accessibility</mat-icon>
            }
          </button>
          <button
            mat-icon-button
            (click)="toggleDirection()"
            [matTooltip]="state.direction === 'rtl' ? 'Switch to LTR' : 'Switch to RTL'"
          >
            @if (state.direction === 'rtl') {
              <mat-icon>west</mat-icon>
            } @else {
              <mat-icon>east</mat-icon>
            }
          </button>
          <button
            #densityTooltip="matTooltip"
            mat-icon-button
            (click)="toggleDensity(undefined, densityTooltip)"
            [matTooltip]="'Density: ' + state.density"
          >
            <mat-icon>grid_on</mat-icon>
          </button>
        </div>
      </div>
    </mat-toolbar>

    <div [ngClass]="getDensityClass()" class="demo-content mat-app-background">
      <ng-content></ng-content>
    </div>
  </main>
</mat-sidenav-container>
